<template>
    <div class="data">
        <div class="race_info">
            <div class="ls_flexalign_center">
                <div>
                    <div>{{ info.totalCoursewareCount }}</div>
                    <div>总计课件</div>
                </div>
                <div>
                    <div>{{ info.totalCoursewareCompleteCount }}</div>
                    <div>累计学习人数</div>
                </div>
            </div>
        </div>
        <div class="ls_flexalign_center search_part">
            <el-date-picker v-model="params.dateRange" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" size="medium"
                style="width: 380px;"></el-date-picker>
            <el-select class="w120" v-model="params.groupId" placeholder="全部标签" size="medium">
                <el-option label="全部" :value="''" />
                <el-option v-for="dict in courseList" :key="dict.id" :label="dict.name" :value="dict.id" />
            </el-select>

            <el-button size="medium" plain @click="(params.pageIndex = 1), getList()">
                查询
            </el-button>
        </div>

        <el-table :data="list" highlight-current-row :header-cell-style="{ background: '#FAFAFA' }" style="width: 100%"
            @sort-change="sort">
            <el-table-column prop="title" label="课件名称"></el-table-column>
            <el-table-column prop="publishUserName" label="创建人"></el-table-column>
            <el-table-column prop="publishTime" label="发布时间"></el-table-column>
            <el-table-column prop="completeNumber" label="学习人数"></el-table-column>
        </el-table>
        <div class="page_part">
            <el-pagination v-if="total" :current-page.sync="params.pageIndex" :page-sizes="[10, 15, 20, 25]"
                :page-size.sync="params.pageSize" :total="total" background layout="total, sizes, prev, pager, next, jumper"
                @size-change="getList" @current-change="getList" />
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            params: {
                dateRange: [],
                groupId: "",
                startTime: "",
                endTime: "",
                pageIndex: 1,
                pageSize: 10,
            },
            total: 0,
            info: {},
            courseList: [],
            list: [],
        };
    },
    created () {
        this.getList();
        this.getInfo();
        this.$ajax.post("/user/group/getList", {}).then(response => {
            this.courseList = JSON.parse(JSON.stringify(response.data));
        });
    },
    methods: {
        sort (e) {
            this.params.sort = e.order;
            this.params.pageIndex = 1;
            this.getList();
        },
        getList () {
            this.$ajax.post("/teachingData/statisticsCoursewareList", {
                ...this.params,
                startTime: this.params.dateRange && this.params.dateRange[0],
                endTime: this.params.dateRange && this.params.dateRange[1],
            }).then(response => {
                this.list = response.list;
                this.total = response.total;
            });
        },
        getInfo () {
            this.$ajax.get("/teachingData/statisticsCoursewareHead").then(response => {
                this.info = response.data;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.data {
    padding-top: 20px;
    padding-right: 30px;
}

.race_info {
    margin-bottom: 30px;

    >div {

        &:last-child {
            >div {
                text-align: center;
                padding: 0 30px;

                +div {
                    border-left: 1px solid rgb(202, 202, 202);
                }

                >div {
                    &:first-child {
                        font-size: 16px;
                    }

                    &:last-child {
                        font-size: 16px;
                        color: #999;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

.search_part {
    >* {
        +* {
            margin-left: 7px;
        }
    }

    margin-bottom: 15px;
}

.page_part {
    text-align: center;
    margin-top: 20px;
}

.w300 {
    width: 300px;
}

.w120 {
    width: 120px;
}
</style>
